import React from 'react';
import UnderConstruction from './components/UnderConstruction';
import Router from './Router'

function App() {

  return (
    <div className="App">
      {/* <Router /> */}
      <UnderConstruction />
    </div>
  );
}

export default App;
